type TProps = {
  handlePreviousQuestion: () => void;
};
export const QuestionButtonBack = ({ handlePreviousQuestion }: TProps) => {
  return (
    <button className="question_button-back" onClick={handlePreviousQuestion}>
      <span>Назад</span>
    </button>
  );
};
