export default function sendEventToCounters(params) {
  const { action, label } = params;

  // eslint-disable-next-line no-console
  console.log('GOAL: ', `nlmk2024_${action}_${label}`, params);

  if (typeof ym !== 'undefined') {
    // eslint-disable-next-line no-undef
    ym(90472438, 'reachGoal', `nlmk2024_${action}_${label}`);
  }
}
