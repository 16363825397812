import { TAnswer } from '../../pages/Game';

type TProps = {
  answer: TAnswer;
  selectedAnswer: string | null;
  handleSelectAnswer: (answer: TAnswer) => void;
};
export const AnswerItem = ({
  answer,
  selectedAnswer,
  handleSelectAnswer,
}: TProps) => {
  const { title, comment, id } = answer;

  const renderClass = () => {
    if (selectedAnswer) {
      return `answer__item ${
        selectedAnswer && selectedAnswer === id
          ? 'answer--selected'
          : 'answer--disabled'
      }`;
    } else {
      return 'answer__item';
    }
  };
  return (
    <li onClick={() => handleSelectAnswer(answer)} className={renderClass()}>
      {title && (
        <p
          className="answer__item-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}

      <p className="answer__item-comment">
        <span>{comment}</span>
      </p>
    </li>
  );
};
