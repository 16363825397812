// import styles from './Home.module.scss';
import clock from './images/clock.svg';
import rocket from './images/rocket.svg';
import heart from './images/heart.svg';
import { Logo } from '../../components/Logo/Logo';
import { Footer } from '../../components/Footer/Footer';
import { MainImg } from '@/src/components/MainImg';
import img from './images/img.png';
import img320 from './images/img-320.png';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';

export const Home = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  return (
    <div className="home">
      <div className="home__content container">
        <div className="home__logo">
          <Logo />
        </div>

        <div className="home__wrapper">
          <p className="home__title">
            Искусственный интеллект или&#160;реальность: проверьте свою интуицию
          </p>
          <p className="home__text">
            Можно&#160;ли управлять краном с&#160;помощью джойстика или
            анализировать настроение сотрудников с&#160;помощью ChatGPT?
            Попробуйте отличить реальные инновации в&#160;металлургии
            от&#160;футуристичной фантазии!
          </p>
          <p className="home__sub-text">
            Пройдите наш тест и&#160;узнайте, какие технологии уже сегодня
            применяются в&#160;Группе НЛМК, а&#160;какие создал искусственный
            интеллект.
          </p>
          <div className="home__button_wrapper">
            <button
              className="home__button button button--orange"
              onClick={() => changePage('game')}
              type="button"
            >
              Начать тест
              <img className="button__icon" src={rocket} alt="" />
            </button>
            <a
              className="home__link button button--clear"
              target="_blank"
              href=""
              rel="noreferrer"
              // onClick={() => {
              //   sendEventToCounters({
              //     action: 'landing',
              //     label: 'vote',
              //   });
              // }}
            >
              Голосовать за НЛМК
              <img className="button__icon" src={heart} alt="" />
              <span className="home__link_accordion">
                Найдите &#171;Группу НЛМК&#187; через поиск и&#160;поставьте
                &#171;сердечко&#187; напротив компании. Спасибо! ♡
              </span>
            </a>
          </div>

          <div className="home__note_wrapper">
            <p className="home__note">
              <img className="home__note_icon" src={clock} alt="" />
              <span>Время прохождения:</span>&#160;3 мин
            </p>
            <p className="home__note-main">
              Найдите нас в&#160;поиске &#171;Группа НЛМК&#187; и&#160;поставьте
              &#171;сердечко&#187; напротив компании.
              <br /> <span>Спасибо! ♡</span>
            </p>
          </div>
        </div>

        <div className="home__footer">
          <Footer />
        </div>
      </div>
      <MainImg img={img} img320={img320} />
    </div>
  );
};
