import hh from './images/hh.svg';

export const Footer = () => {
  return (
    <div className="footer">
      <p className="footer__note footer__note--company">Ⓒ НЛМК 2024</p>
      <p className="footer__note footer__note--brend">
        <img className="footer__note-icon" src={hh} alt="hh.ru" />
        Бренд-центр
      </p>
    </div>
  );
};
