import imageQuestion1_1 from './images/question1_1.jpg';
import imageQuestion1_2 from './images/question1_2.jpg';
import imageQuestion1_1_mobile from './images/question1_1-mobile.jpg';
import imageQuestion1_2_mobile from './images/question1_2-mobile.jpg';

import imageQuestion3_1 from './images/question3_1.jpg';
import imageQuestion3_2 from './images/question3_2.jpg';

import imageQuestion4_1 from './images/question4_1.jpg';
import imageQuestion4_2 from './images/question4_2.jpg';

import imageQuestion5_1 from './images/question5_1.jpg';

import imageQuestion6_1 from './images/question6_1.jpg';
import imageQuestion6_2 from './images/question6_2.jpg';

export default [
  {
    title:
      'Современная металлургия развивается каждый день. Ученые и специалисты со всего мира работают над созданием новых видов стали. Как считаете, какой предмет из стали на фото сгенерирован ИИ, а какой реальный?',
    type: 'card',
    answers: [
      {
        id: '1_1',
        title:
          'Голографическое управление: оператор работает с виртуальной моделью производственного процесса в 3D и взаимодействует с ней с помощью жестов',
        comment:
          '😐 Увы! Голографическое управление – пока еще технология будущего, однако, не такого далекого. Виртуальные панели управления для нас уже не за горам.',
        img: imageQuestion1_1,
        img_mobile: imageQuestion1_1_mobile,
        correct_answer: true,
      },
      {
        id: '1_2',
        title:
          'Удалённое управление: оператор управляет краном из специально оборудованного пространства по изображениям с камер в цехах с помощью джойстика',
        comment:
          '😊 Именно так! Уже сегодня это современное рабочее место машиниста крана, который работает из центра управления из специальных кресел, оборудованных мониторами и джойстиками. Ждем в свои ряды профессиональных игроков киберспорта 🎮 ',
        img: imageQuestion1_2,
        img_mobile: imageQuestion1_2_mobile,
        correct_answer: false,
      },
    ],
  },

  {
    title: 'Как НЛМК использует ChatGPT?',
    type: 'list',
    answers: [
      {
        id: '2_1',
        title:
          'Анализирует корпоративную культуру и настроение сотрудников через анонимные опросы',
        comment:
          '😊Верно! Модель может предоставлять пошаговую инструкцию по устранению неисправностей на основе данных о текущем состоянии оборудования.',
        correct_answer: true,
      },
      {
        id: '2_2',
        title: 'Составляет каверзные вопросы для собеседований',
        comment:
          '😐К сожалению, у ChatGPT есть свои ограничения. Но зато он может создавать модель, которая создает пошаговую инструкцию по устранению неисправностей на основе данных о текущем состоянии оборудования. Технологии не перестают нас удивлять!',
        correct_answer: false,
      },
      {
        id: '2_4',
        title: 'Помогает диагностировать и ремонтировать оборудование',
        comment:
          '😐К сожалению, у ChatGPT есть свои ограничения. Но зато он может создавать модель, которая создает пошаговую инструкцию по устранению неисправностей на основе данных о текущем состоянии оборудования. Технологии не перестают нас удивлять!',
        correct_answer: false,
      },
      {
        id: '2_5',
        title: 'Полностью придумал этот тест и пытается вас запутать',
        comment:
          '😐К сожалению, у ChatGPT есть свои ограничения. Но зато он может создавать модель, которая создает пошаговую инструкцию по устранению неисправностей на основе данных о текущем состоянии оборудования. Технологии не перестают нас удивлять!',
        correct_answer: false,
      },
    ],
  },

  {
    title:
      'Металлургия успешно осваивает новые технологии. Как думаете, какие технологии мы применяем уже сейчас, а какие — плод воображения искусственного интеллекта?',
    type: 'card',
    answers: [
      {
        id: '3_1',
        title:
          'Удалённое управление: оператор управляет краном из специально оборудованного пространства по изображениям с камер в цехах с помощью джойстика',
        comment:
          '✅ Именно так! Уже сегодня это современное рабочее место машиниста крана, который работает из центра управления из специальных кресел, оборудованных мониторами и джойстиками. Ждем в свои ряды профессиональных игроков киберспорта 😊 Технологическое развитие – долгосрочный вызов и один из ключевых факторов успеха для НЛМК. Мы развиваем знания, компетенции наших сотрудников и модернизируем условия их труда.',
        img: imageQuestion3_1,
        correct_answer: true,
      },
      {
        id: '3_2',
        title:
          'Голографическое управление: оператор работает с виртуальной моделью производственного процесса в 3D и взаимодействует с ней с помощью жестов',
        comment:
          '😐 Увы! Голографическое управление – пока еще технология будущего, однако, не такого далекого. Виртуальные панели управления для нас уже не за горам. ',
        img: imageQuestion3_2,
        correct_answer: false,
      },
    ],
  },

  {
    title: 'Проверьте свою интуицию, найдите изображение стальных конструкций',
    type: 'card',
    answers: [
      {
        id: '4_1',
        comment:
          '😊 Вы абсолютно правы! Это был сложный вопрос. Перед вами изображение высокопрочного листа. Его используют для опоры офшорных конструкций ветрогенераторов. Впечатляет, не правда ли?',
        img: imageQuestion4_1,
        correct_answer: true,
      },
      {
        id: '4_2',
        comment:
          '😐 Это был сложный вопрос. Навряд ли вам попадались изображения стали под микроскопом в повседневной жизни. Перед вами изображение микроструктуры картофеля. Впечатляет, не правда ли?',
        img: imageQuestion4_2,
        correct_answer: true,
      },
    ],
  },

  {
    title:
      'Как вы считаете, какая профессия у героя снимка, а какие варианты предложил ИИ?',
    type: 'grid',
    img: imageQuestion5_1,
    answers: [
      {
        id: '5_1',
        title: 'Электро&#173;газосварщик',
        comment:
          '✅ В машиностроительном управлении НЛМК сваркой серийных деталей теперь занимается робот. Он, конечно, работает не в одиночку, а в паре с человеком - электрогазосварщиком. Рабочий процесс стал безопаснее и экологичнее: специалист управляет механизмом на расстоянии, а световой барьер роботизированного комплекса автоматически отключается, если в рабочую зону заходит человек. Эффект от применения такого робота — скорость изготовления деталей увеличилась приблизительно вдвое!',
        correct_answer: true,
      },
      {
        id: '5_2',
        title: 'Электромонтер',
        comment:
          '😐 В машиностроительном управлении НЛМК сваркой серийных деталей теперь занимается робот. Он, конечно, работает не в одиночку, а в паре с человеком - электрогазосварщиком. Рабочий процесс стал безопаснее и экологичнее: специалист управляет механизмом на расстоянии, а световой барьер роботизированного комплекса автоматически отключается, если в рабочую зону заходит человек. Эффект от применения такого робота — скорость изготовления деталей увеличилась приблизительно вдвое!',
        correct_answer: false,
      },
      {
        id: '5_3',
        title: 'Повелитель роботов',
        comment:
          '😐 В машиностроительном управлении НЛМК сваркой серийных деталей теперь занимается робот. Он, конечно, работает не в одиночку, а в паре с человеком - электрогазосварщиком. Рабочий процесс стал безопаснее и экологичнее: специалист управляет механизмом на расстоянии, а световой барьер роботизированного комплекса автоматически отключается, если в рабочую зону заходит человек. Эффект от применения такого робота — скорость изготовления деталей увеличилась приблизительно вдвое!',
        correct_answer: false,
      },
      {
        id: '5_4',
        title: 'Созидатель синтетических миров',
        comment:
          '😐 В машиностроительном управлении НЛМК сваркой серийных деталей теперь занимается робот. Он, конечно, работает не в одиночку, а в паре с человеком - электрогазосварщиком. Рабочий процесс стал безопаснее и экологичнее: специалист управляет механизмом на расстоянии, а световой барьер роботизированного комплекса автоматически отключается, если в рабочую зону заходит человек. Эффект от применения такого робота — скорость изготовления деталей увеличилась приблизительно вдвое!',
        correct_answer: false,
      },
    ],
  },

  {
    title:
      'Мы создали мурал с дополненной реальностью, который олицетворяет образ металлургии будущего. Как вы думаете, какой эскиз лег в основу, а какой – видение ИИ?',
    type: 'card-small',
    answers: [
      {
        id: '6_1',
        comment:
          '✅ Абсолютно верно! Этот эскиз лег в основу мурала с дополненной реальностью, который мы нарисовали в Липецке. Он олицетворяет образ металлурга, который производит продукты будущего.  Вы не поверите, но часть этих продуктов – уже стали для нас реальностью за эти два года, а впереди у нас новые художественные проекты на эту тему.<br><br/>Технологии помогают нам сделать наших коллег еще сильнее. Мы верим: металлургия будущего создается сегодня благодаря каждому нашему сотруднику.',
        img: imageQuestion6_1,
        correct_answer: true,
      },
      {
        id: '6_2',
        comment:
          '😐 Нейросеть вас запутала! Наше видение металлургии будущего, возможно, показалось вам не таким реальным, но это только на первый взгляд. Ведь без человека сложно представить будущее, даже технологичное 🚀',
        img: imageQuestion6_2,

        correct_answer: false,
      },
    ],
  },
];
