// import bck from './images/bck.png';
// import bck_2x from './images/bck@2x.png';

// import bck_1024 from './images/bck-1024.png';
// import bck_1024_2x from './images/bck-1024@2x.png';

// import bck_768 from './images/bck-768.png';
// import bck_768_2x from './images/bck-768@2x.png';

// import bck_320 from './images/bck-320.png';
// import bck_320_2x from './images/bck-320@2x.png';

type TProps = {
  img: string;
  img320: string;
};

export const MainImg = ({ img, img320 }: TProps) => {
  return (
    <div className="main-img">
      <picture>
        {/* <source
          srcSet={`${bck_320} 1x, ${bck_320_2x} 2x`}
          media="(max-width: 699px)"
        /> */}
        <source srcSet={img320} media="(max-width: 1019px)" />
        {/* <source
          srcSet={`${bck_1024} 1x, ${bck_1024_2x} 2x`}
          media="(max-width: 1339px)"
        /> */}
        <img src={img} alt="" />
      </picture>
    </div>
  );
};
