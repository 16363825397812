import { TAnswer } from '@/src/pages/Game';
import cn from 'classnames';
type TProps = {
  answer: TAnswer;
  selectedAnswer: string | null;
  handleSelectAnswer: (answer: TAnswer) => void;
};
export const AnswerCard = ({
  answer,
  selectedAnswer,
  handleSelectAnswer,
}: TProps) => {
  const { title, id, img, img_mobile, comment } = answer;
  return (
    <div
      key={id}
      className={cn('answer-card', {
        ['answer--selected']: selectedAnswer === id,
      })}
      onClick={() => handleSelectAnswer(answer)}
    >
      <div className="answer-card__img">
        <picture>
          <source srcSet={img_mobile} media="(max-width: 699px)" />
          <img src={img} alt="" />
        </picture>
        {title && (
          <p
            className="answer-card__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </div>

      {comment && (
        <p className="answer-card__comment">
          <span dangerouslySetInnerHTML={{ __html: comment }}></span>
        </p>
      )}
    </div>
  );
};
