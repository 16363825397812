import { useState, useEffect } from 'react';

import { TAnswer, TQuestion } from '../../pages/Game';
import { QuestionButtonBack } from '../QuestionButtonBack';
import { AnswerItem } from '../AnswerItem/AnswerItem';
import { AnswersGrid } from '../AnswersGrid/AnswersGrid';
import { AnswerCard } from '../AnswerCard';
import { useAppDispatch, useAppSelector } from '@/src/hooks';
import { currentGameAnswersSelector } from '@/src/store/selectors/game';
import { addAnswer, setAnswers } from '@/src/store/slices/gameSlice';

type TProps = {
  question?: TQuestion;
  handlePreviousQuestion: () => void;
  handleNextQuestion: () => void;
  currentQuestion: number;
};
export const Answers = ({
  question,
  handlePreviousQuestion,
  handleNextQuestion,
  currentQuestion,
}: TProps) => {
  if (!question) {
    return null;
  }
  const { type, answers } = question;

  const [selectedAnswer, setSelectedAnswer] = useState<TAnswer | null>(null);

  const resultAnswers = useAppSelector(currentGameAnswersSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (resultAnswers[currentQuestion]) {
      setSelectedAnswer(resultAnswers[currentQuestion]);
    } else {
      setSelectedAnswer(null);
    }
  }, [currentQuestion]);

  const handleSelectAnswer = (answer: TAnswer) => {
    setSelectedAnswer(answer);
  };

  const renderAnswerCard = () => {
    return (
      <div className="answers__card-list">
        {answers.map((answer) => (
          <AnswerCard
            key={answer.id}
            answer={answer}
            selectedAnswer={selectedAnswer ? selectedAnswer.id : null}
            handleSelectAnswer={handleSelectAnswer}
          />
        ))}
      </div>
    );
  };

  const renderAnswersList = () => {
    return (
      <ul className="answers__list">
        {answers.map((answer) => (
          <AnswerItem
            key={answer.id}
            answer={answer}
            selectedAnswer={selectedAnswer ? selectedAnswer.id : null}
            handleSelectAnswer={handleSelectAnswer}
          />
        ))}
      </ul>
    );
  };

  const renderAnswersGrid = () => (
    <AnswersGrid
      selectedAnswer={selectedAnswer ? selectedAnswer.id : null}
      handleSelectAnswer={handleSelectAnswer}
      answers={answers}
      answerImg={question.img ? question.img : ''}
    />
  );
  const renderAnswerSmallCard = () => {
    return (
      <div className="answers__card-list answers__card-list--small">
        {answers.map((answer) => (
          <AnswerCard
            key={answer.id}
            answer={answer}
            selectedAnswer={selectedAnswer ? selectedAnswer.id : null}
            handleSelectAnswer={handleSelectAnswer}
          />
        ))}
      </div>
    );
  };
  const renderAnswers = () => {
    switch (type) {
      case 'card':
        return renderAnswerCard();
        break;
      case 'card-small':
        return renderAnswerSmallCard();
        break;
      case 'list':
        return renderAnswersList();
        break;
      case 'grid':
        return renderAnswersGrid();
      default:
        return 'answers__list';
    }
  };

  const handleClickNext = () => {
    if (selectedAnswer) {
      if (resultAnswers[currentQuestion]) {
        const temp = [...resultAnswers];
        temp[currentQuestion] = selectedAnswer;

        dispatch(setAnswers(temp));
      } else {
        dispatch(addAnswer(selectedAnswer));
      }

      // setResults((prev) => {
      //   if (prev[currentQuestion]) {
      //     const temp = prev;
      //     temp[currentQuestion] = selectedAnswer;
      //     return temp;
      //   } else {
      //     return [...prev, selectedAnswer];
      //   }
      // });

      handleNextQuestion();
    }
  };

  return (
    <div className="answers">
      <div>{renderAnswers()}</div>
      <div className="answers__controls">
        <QuestionButtonBack handlePreviousQuestion={handlePreviousQuestion} />
        <button
          className="answers__next-question-button button button--blue"
          disabled={!selectedAnswer}
          onClick={handleClickNext}
        >
          Далее
        </button>
      </div>
    </div>
  );
};
