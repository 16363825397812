import { noop } from '@/src/utils/noop';
import { usePageContext } from '../App/PageContext';
import styles from './Final.module.scss';
import { useAppSelector } from '@/src/hooks';
import { currentGameAnswersSelector } from '@/src/store/selectors/game';

export const Final = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const answers = useAppSelector(currentGameAnswersSelector);

  console.log('answers', answers);

  return (
    <>
      <div className={styles.final}>
        Игра завершена
        <button onClick={() => changePage('home')}>Играть еще раз</button>
      </div>
    </>
  );
};
