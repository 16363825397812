import { TQuestion } from '../../pages/Game';
import { QuestionCounter } from '../QuestionCounter';
import { QuestionButtonBack } from '../QuestionButtonBack/QuestionButtonBack';

type TProps = {
  currentQuestion: number;
  countQuestions: number;
  question?: TQuestion;
  handlePreviousQuestion: () => void;
};

export const Question = ({
  currentQuestion,
  countQuestions,
  question,
  handlePreviousQuestion,
}: TProps) => {
  if (!question) {
    return null;
  }
  const { title } = question;

  return (
    <div className="question">
      <QuestionCounter
        currentQuestion={currentQuestion}
        countQuestions={countQuestions}
      />
      <div className="question__head-wrapper">
        <QuestionButtonBack handlePreviousQuestion={handlePreviousQuestion} />
        <h2
          className={`question__title question__title--${currentQuestion + 1}`}
        >
          {title}
        </h2>
      </div>
    </div>
  );
};
