import { TAnswer } from '../../pages/Game';
import { AnswerItem } from '../AnswerItem';
import cn from 'classnames';
type TProps = {
  answers: TAnswer[];
  selectedAnswer: string | null;
  handleSelectAnswer: (answer: TAnswer) => void;
  answerImg: string;
};
export const AnswersGrid = ({
  answers,
  selectedAnswer,
  handleSelectAnswer,
  answerImg,
}: TProps) => {
  return (
    <div className="answers__grid">
      <div
        className={cn('answer-card', {
          ['answer--selected']: selectedAnswer,
        })}
      >
        <img src={answerImg} alt="" />
        <p className="answer-card__comment">
          {answers.map((answer) =>
            answer.id === selectedAnswer ? answer.comment : '',
          )}
        </p>
      </div>
      <ul className="answers__list">
        {answers.map((answer) => (
          <AnswerItem
            key={answer.id}
            answer={answer}
            selectedAnswer={selectedAnswer ? selectedAnswer : null}
            handleSelectAnswer={handleSelectAnswer}
          />
        ))}
      </ul>
      <p
        className={cn('answer-card__comment answer-card__comment--mobile', {
          ['answer--selected']: selectedAnswer,
        })}
      >
        {answers.map((answer) =>
          answer.id === selectedAnswer ? answer.comment : '',
        )}
      </p>
    </div>
  );
};
