import { TAnswer } from '@/src/pages/Game';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  answers: TAnswer[];
}

const initialState: IState = {
  answers: [],
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setAnswers(state, action: PayloadAction<TAnswer[]>) {
      state.answers = action.payload;
    },
    addAnswer(state, action: PayloadAction<TAnswer>) {
      state.answers.push(action.payload);
    },
    resetGame(state) {
      state.answers = [];
    },
  },
});

export const { setAnswers, addAnswer, resetGame } = gameSlice.actions;

export default gameSlice;
