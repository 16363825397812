type TProps = {
  currentQuestion: number;
  countQuestions: number;
};

export const QuestionCounter = ({
  currentQuestion,
  countQuestions,
}: TProps) => {
  return (
    <div className="question__counter">
      Вопрос {currentQuestion + 1}/{countQuestions}
    </div>
  );
};
