import questions from './data';
import { useState, useEffect } from 'react';
import { Question } from '../../components/Question/Question';
import { Answers } from '../../components/Answers/Answers';
import sendEventToCounters from '@/src/counterEvents';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';

export type TAnswer = {
  title?: string;
  img?: string;
  img_mobile?: string;
  id: string;
  comment?: string;
};

export type TQuestion = {
  title: string;
  type: string;
  answers: TAnswer[];
  img?: string;
};

export const Game = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const countQuestions = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    } else {
      changePage('home');
    }

    sendEventToCounters({
      action: `question-${currentQuestion + 1}`,
      label: 'back',
    });
  };

  const handleNextQuestion = () => {
    sendEventToCounters({
      action: `question-${currentQuestion + 1}`,
      label: 'further',
    });
    setCurrentQuestion((prev) => prev + 1);
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  };

  useEffect(() => {
    scrollToTop();
    if (currentQuestion > countQuestions - 1) {
      changePage('final');
    }
  }, [currentQuestion]);

  return (
    <div className="game">
      <div className="game__content container">
        <Question
          countQuestions={countQuestions}
          currentQuestion={currentQuestion}
          question={questions[currentQuestion]}
          handlePreviousQuestion={handlePreviousQuestion}
        />
        <Answers
          question={questions[currentQuestion]}
          handlePreviousQuestion={handlePreviousQuestion}
          handleNextQuestion={handleNextQuestion}
          currentQuestion={currentQuestion}
        />
      </div>
    </div>
  );
};
